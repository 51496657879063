import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

// components and helpers
import logo from '../assets/images/NewOrganDataLogo.svg';
import organDataGraphic from '../assets/images/OrganDataGraphic.png';

// mui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';
import CardActionArea from '@mui/material/CardActionArea';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material';

function getStyles(option: string, waitlistOptions: string[], theme: Theme) {
    return {
        fontWeight:
            waitlistOptions.indexOf(option) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const HomeView: React.FC = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const theme = useTheme();
    const [questionInput, setQuestionInput] = React.useState<string>('');
    const [waitlistForm, setWaitlistForm] = React.useState({
        email: '',
        name: '',
        message: '',
        interest: [],
    });
    const promptQuestions = [
        'What are the most recent trends in organ transplants by demographic groups (e.g., age, gender, ethnicity)?',
        'Can you provide a comparison of organ procurement rates across different OPOs?',
        'Can you analyze the trends in kidney transplant waitlist numbers over the past few years?',
    ];
    const waitlistOptions = [
        'Organ Donation Statistics',
        'Waitlist Dynamics',
        'Transplant Rates',
        'OPO Performance',
        'Real-time Data Visualization',
        'Research Support',
        'Healthcare Decision-Making',
        'Ethical AI in Organ Data',
    ];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const disabled = !waitlistForm.email || !waitlistForm.name || !waitlistForm.interest.length;

    useEffect(() => {
        setWaitlistForm({
            email: '',
            name: '',
            message: '',
            interest: [],
        });
        return () => {
            setWaitlistForm({
                email: '',
                name: '',
                message: '',
                interest: [],
            });
        };
    }, []);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        Cookies.remove('auth0_user', { path: '/' });
        Cookies.remove('auth0_token', { path: '/' });
        logout();
    };

    const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            window.open('https://chat.organdata.org/', '_blank');
        }
    };

    const handleInputChange = (e: { target: { name: any; value: any } }) => {
        const { name, value } = e.target;
        setWaitlistForm({
            ...waitlistForm,
            [name]: value,
        });
    };

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setWaitlistForm({
            ...waitlistForm,
            // @ts-ignore
            interest: typeof value === 'string' ? value.split(',') : value,
        });
    };

    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundColor: '#0b0b0b',
                width: '100%',
                minHeight: '100vh', // This ensures that the background extends even with scrolling content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Grid container justifyContent={'space-between'} alignItems={'flex-end'} sx={{ p: 4 }} spacing={2}>
                <Grid item xs={12} md={6}>
                    <img src={logo} alt="organ-data-logo" style={{ maxWidth: 200 }} />
                </Grid>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                href={'/about'}
                                style={{ marginRight: 10, color: '#fff', borderColor: '#fff' }}
                            >
                                About OrganData.org
                            </Button>
                        </Grid>
                        {isAuthenticated ? (
                            <Grid item>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    href={'/dashboard/overview'}
                                    style={{ color: '#0b0b0b' }}
                                >
                                    Analytics Dashboard
                                </Button>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="primary"
                                >
                                    <AccountCircle sx={{ fontSize: 36 }} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{ color: '#0b0b0b' }}
                                    onClick={() => loginWithRedirect()}
                                >
                                    Login
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                justifyContent={'space-evenly'}
                alignItems={'center'}
                sx={{ p: 4, flexGrow: 1 }}
                spacing={6}
            >
                {isAuthenticated ? (
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={6}>
                            <Grid item>
                                <h1 style={{ color: 'white', fontWeight: 'bold' }}>
                                    How can we support your organ journey?
                                </h1>
                            </Grid>
                            <Grid item>
                                <h4 style={{ color: 'white' }}>
                                    Access the latest insights and trends in organ donation and transplantation to
                                    empower informed decision-making.
                                </h4>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="filled" fullWidth>
                                    <OutlinedInput
                                        sx={{
                                            width: '100%',
                                            backgroundColor: '#fff !important',
                                            fontSize: '0.8rem',
                                        }}
                                        id="outlined-prompt"
                                        placeholder={'Type your question here'}
                                        margin="none"
                                        onKeyUp={handleSend}
                                        value={questionInput}
                                        onChange={(e) => setQuestionInput(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => window.open('https://chat.organdata.org/', '_blank')}
                                                >
                                                    <SendRoundedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <Grid container spacing={1} sx={{ mt: 1 }}>
                                        {promptQuestions.map((question, index) => (
                                            <Grid item xs={4} key={index}>
                                                <Card sx={{ maxWidth: '100%', minHeight: 100 }}>
                                                    <CardActionArea onClick={() => setQuestionInput(question)}>
                                                        <CardContent>
                                                            <Typography
                                                                variant="caption"
                                                                sx={{ color: 'text.secondary' }}
                                                            >
                                                                {question}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={6}>
                            <Grid item>
                                <h1 style={{ color: 'white', fontWeight: 'bold' }}>
                                    How can we support your organ journey?
                                </h1>
                            </Grid>
                            <Grid item>
                                <h4 style={{ color: 'white' }}>
                                    Access the latest insights and trends in organ donation and transplantation to
                                    empower informed decision-making through our analytics dashboard and AI companion.
                                </h4>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Paper sx={{ p: 2 }}>
                                    <form action="https://formspree.io/f/movqkenj" method="POST">
                                        <Stack spacing={1}>
                                            <Typography variant={'h5'} sx={{ mb: 1 }}>
                                                Join the Beta Program Waitlist
                                            </Typography>
                                            <TextField
                                                id="outlined-basic"
                                                label="Email *"
                                                type="email"
                                                name="email"
                                                variant="outlined"
                                                fullWidth
                                                value={waitlistForm.email}
                                                onChange={handleInputChange}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Name *"
                                                name="name"
                                                variant="outlined"
                                                fullWidth
                                                value={waitlistForm.name}
                                                onChange={handleInputChange}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-multiple-name-label">
                                                    What are you most interested in? * (Select all that apply)
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="interest"
                                                    multiple
                                                    name="interest"
                                                    // @ts-ignore
                                                    value={waitlistForm.interest}
                                                    onChange={handleChange}
                                                    input={
                                                        <OutlinedInput
                                                            label="What are you most interested
                                                    in? * (Select all that apply)"
                                                        />
                                                    }
                                                >
                                                    {waitlistOptions.map((option) => (
                                                        <MenuItem
                                                            key={option}
                                                            value={option}
                                                            style={getStyles(option, waitlistForm.interest, theme)}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                id="outlined-basic"
                                                label="Message"
                                                name="message"
                                                variant="outlined"
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                value={waitlistForm.message}
                                                onChange={handleInputChange}
                                            />
                                            <Button type={'submit'} variant={'contained'} disabled={disabled}>
                                                Submit
                                            </Button>
                                        </Stack>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} md={5}>
                    <img src={organDataGraphic} alt="organ-data-logo" style={{ maxWidth: '100%' }} />
                </Grid>
            </Grid>

            <Grid container justifyContent={'center'} sx={{ p: 4 }}>
                <Grid item>
                    <Typography style={{ color: 'white', textAlign: 'center' }} variant={'h6'}>
                        Built by{' '}
                        <a
                            href={'https://cryptographicdatamesh.com/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            Acoer Cryptographic Data Mesh
                        </a>
                        , powered by organ procurement and transplant data from{' '}
                        <a
                            href={'https://optn.transplant.hrsa.gov/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            OPTN
                        </a>
                        ,{' '}
                        <a
                            href={'https://www.srtr.org/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            SRTR
                        </a>
                        , and{' '}
                        <a
                            href={'https://www.hrsa.gov/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            HRSA
                        </a>
                        , with{' '}
                        <a
                            href={'https://www.acoer.com/news/blog/ethical-ai-in-healthcare'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            Ethical AI-driven
                        </a>{' '}
                        insights.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeView;
